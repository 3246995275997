.home__main-container {
  background-color: var(--color-secondary);
  width: 100%;
  height: 100%;
}

.home__main-container--no-scroll {
  background-color: var(--color-secondary);
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.home__main-page-container {
  margin: 0px auto;
  padding: 2rem 15%;
  display: flex;
  flex-direction: column;
}