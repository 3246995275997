.about-section__main-container {
  padding-top: 5rem;
  padding-bottom: 2rem;
  min-height: 70vh;
}

.about-section__bio-container {
  width: 65%;
  float: left;
  margin: 0 3%;
  padding: 2%;
}

.about-section__img-container {
  width: 25%;
  margin: 0 3%;
  position: relative;
}

.about-section__skills-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0px 1rem;
  padding: 0;
  margin: 2rem 0px 0px;
  overflow: hidden;
  list-style: none;
}

.about-section__bio-img-container {
  display: flex;
  flex-direction: row;
}

.about-section__skills-list li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.about-section__skills-list li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  top: 5px;
  color: var(--color-primary);
  font-size: var(--fz-sm);
  line-height: 12px;
}

.about-section__img-item {
  min-height: 40px;
  min-width: 40px;
  aspect-ratio: 1;
  border-radius: 2%;
  background-image: url("../../../public/assets/quentin-portrait.png");
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.about-section__img-item:hover {
  filter: none;
}

.about-section__img-item:hover {
  filter: none;
}

.about-section__img-item:hover+.about-section__img-item--border {
  transform: translate(-4%, -4%);
}

.about-section__img-item--border {
  aspect-ratio: 1;
  min-height: 40px;
  min-width: 40px;
  border-style: solid;
  border-width: 2px;
  border-radius: 2%;
  border-color: var(--color-primary);
  position: absolute;
  left: 20px;
  right: -20px;
  top: 20px;
  bottom: -20px;
  z-index: 2;
  transition: transform 0.2s;
}

@media screen and (max-width: 800px) {
  .about-section__bio-img-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .about-section__bio-container {
    width: 100%;
    height: 50%;
  }

  .about-section__img-container {
    margin: 2rem 0;
    width: 60%;
    height: 300px;
  }

  .about-section__img-item {
    height: 300px;

    left: 40%;
  }

  .about-section__img-item--border {
    height: 300px;
    left: 45%;

    right: -5%;
    top: 5%;
    bottom: -5%;
  }
}