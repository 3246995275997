.social__main-container {
  position: fixed;
  width: 40px;
  right: auto;
  left: 40px;
  z-index: 10;
  bottom: 4rem;
}

.social__item {
  padding: 0.5rem 0;
  color: var(--color-gray);
  cursor: pointer;
}

.social__item:hover {
  color: var(--color-primary);
}

@media screen and (max-width: 1080px) {
  .social__main-container {
    right: auto;
    left: 20px;
  }
}

@media screen and (max-width: 800px) {
  .social__main-container {
    position: inherit;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .social__item {
    padding: 0.5rem 1rem;
    color: var(--color-gray);
    cursor: pointer;
  }
}