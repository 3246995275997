.navlink-container {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-gray);
}

.navlink-container--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-gray);
}

.navlink-number {
  color: var(--color-primary);
}

.navlink-title {
  padding-left: 0.5rem;
  color: inherit;
}

.navlink-title--mobile {
  color: inherit;
  font-size: 18px;


}

.navlink-number--mobile {
  color: var(--color-primary);
}

.navlink-container:hover {
  color: var(--color-primary);
}

.navlink-container--mobile:hover {
  color: var(--color-primary);
}