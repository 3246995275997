.intro__main-container {
  height: 100vh;
  margin-top: 120px;
}
.intro__button-container {
  padding: 2rem 0;

  display: flex;
  justify-content: flex-start;
}
.intro__text-container {
  padding: 2rem 0;
}

.intro__text-link {
  color: var(--color-primary);
  text-decoration: none;
}
