.button__container {
  padding: 0.7rem 1.5rem;
  border-style: solid;
  border-radius: 0.3rem;
  border-width: 0.12rem;
  border-color: var(--color-primary);
  cursor: pointer;
  text-decoration: none;
}

.button__title {
  color: var(--color-primary);
  text-decoration: none;
}

.button__container:hover {
  background-color: var(--color-primary-background);
}